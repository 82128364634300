/**
 * Checks if a specified component is an actual dialog.
 * @param {HTMLElement} component - The component to check.
 * @returns {HTMLDialogElement | null} The dialog element if it exists and is an instance of HTMLDialogElement; otherwise, null.
 */
const getActualDialog = (component: HTMLElement): HTMLDialogElement | null => {
  const dialog = component.querySelector<HTMLElement>('[ck-dialog]')
  return dialog && dialog instanceof HTMLDialogElement ? dialog : null
}

/**
 * Checks if a specified component has a ck-dialog-video-src attribute
 * @param component - The component to check.
 * @returns { HTMLElement | null}
 */
const getVideo = (component: HTMLElement): HTMLElement | null => {
  const video = component.querySelector<HTMLElement>('[ck-dialog-video-src]')
  return video ? video : null
}

/**
 * Closes a specified dialog.
 * @param {HTMLElement} component - The component containing the dialog needing to be closed.
 */
const closeDialog = (component: HTMLElement): void => {
  const dialog = getActualDialog(component)
  const video = getVideo(component)
  let videoSrc = ''

  if (dialog) {
    dialog.close()
    component.classList.remove('open')

    if (video) {
      videoSrc = video.getAttribute('src') || ''
      video.setAttribute('ck-dialog-video-src', videoSrc)
      video.setAttribute('src', '')
    }
  }
}

/**
 * Handles clicks outside of the dialog.
 * @param {Event} event - The click event.
 */
const handleOutsideClick = (event: MouseEvent): void => {
  const thisComponent = (event.target as HTMLElement).closest('[ck-dialog-component]') as HTMLElement

  if (!thisComponent) return

  const dialog = getActualDialog(thisComponent)

  if (dialog && dialog.open) {
    const dialogBoundary = dialog.getBoundingClientRect()
    const clickedInside =
      event.clientX >= dialogBoundary.left &&
      event.clientX <= dialogBoundary.right &&
      event.clientY >= dialogBoundary.top &&
      event.clientY <= dialogBoundary.bottom

    if (!clickedInside) {
      closeDialog(thisComponent)
    }
  }
}

/**
 * Attaches click listeners to all elements with [ck-dialog-open="id"].
 * Where "id" matches a particular dialog component's id.
 */
const attachOpenDialogListeners = (): void => {
  const components = document.querySelectorAll<HTMLElement>('[ck-dialog-component]')
  const dialogOpeners = document.querySelectorAll('[ck-dialog-open]')

  dialogOpeners.forEach((dialogOpener) => {
    const targetDialogId = dialogOpener.getAttribute('ck-dialog-open')
    components.forEach((component) => {
      const dialog = getActualDialog(component)
      const video = getVideo(component)
      let videoSrc = ''

      if (dialog && component.id === targetDialogId) {
        dialogOpener.addEventListener('click', () => {
          if (dialog.getAttribute('ck-dialog-non-modal')) {
            dialog.show()
            setTimeout(() => {
              document.body.style.overflow = 'visible'
            }, 300)
          } else {
            dialog.showModal() // use native showModal method directly
          }
          document.body.style.overflow = 'hidden'
          component.classList.add('open')
          document.addEventListener('click', handleOutsideClick)

          if (video) {
            // figure out what to use as the src
            const videoSrcReal = video.getAttribute('src') || ''
            if (videoSrcReal.includes('youtube')) {
              videoSrc = video.getAttribute('src') + '?&autoplay=1&mute=0'
            } else if (videoSrcReal === '') {
              videoSrc = video.getAttribute('ck-dialog-video-src') || ''
            } else {
              videoSrc = videoSrcReal
            }

            video.setAttribute('src', videoSrc)
          }
        })
      }
    })
  })
}

/**
 * Initializes accessible dialogs and binds event listeners.
 *
 * @example
 * ```html
 * <button ck-dialog-open="dialog-id">Open Dialog</button>
 * <div ck-dialog-component id="dialog-id">
 *   <div ck-dialog-overlay></div>
 *   <dialog ck-dialog> <!-- this can be achieved with ck-convert-tag="dialog" -->
 *     <h2>Dialog Title</h2>
 *     <p>This is a dialog.</p>
 *     <iframe
 *       ck-dialog-video-src
 *       src=""
 *       title=""
 *       style="border:0;position:absolute;left:0;top:0;width:100%;height:100%;pointer-events:auto"
 *       allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
 *       allowfullscreen="true"
 *     ></iframe>
 *     <button ck-dialog-close>Close</button>
 *   </dialog>
 * </div>
 * ```
 */
export default function initializeAccessibleDialogs(): void {
  const components = document.querySelectorAll<HTMLElement>('[ck-dialog-component]')

  components.forEach((component) => {
    const dialog = getActualDialog(component)

    if (dialog) {
      const closeButtons = component.querySelectorAll('[ck-dialog-close]')

      closeButtons.forEach((closeButton) => {
        closeButton.addEventListener('click', () => closeDialog(component))
      })

      dialog.addEventListener('close', () => {
        document.body.style.overflow = ''
        component.classList.remove('open')
        document.removeEventListener('click', handleOutsideClick)
      })
    }
  })

  attachOpenDialogListeners()
}
