import Accordions from './components/accordion'
import Anchors from './components/anchors'
import Cards from './components/card'
import CountUp from './components/countUp'
import Dialogs from './components/dialog'
import Dropdowns from './components/dropdown'
// import NavScroll from './components/navScroll'

console.info('%c🔥CK Started!', 'color: red; font-size: 1rem;')

// accessibility enhancements
Accordions()
Anchors()
Cards()
Dialogs()
Dropdowns()

// gsap
CountUp()
// NavScroll()
